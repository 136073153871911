export const state = {
  setMenuToggle: false
};

export const actions = {
  setMenu({ commit }, payLoad) {
    commit("stateMenu", payLoad);
    // if ((state.count + rootState.count) % 2 === 1) {
    //   commit("increment");
    // }
  }
};

/* eslint no-param-reassign: ["error", { "props": false }] */
export const mutations = {
  stateMenu(state, val) {
    state.setMenuToggle = val;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
