export const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/create-report",
    name: "create-report",
    component: () => import("@/pages/CreateReport")
  },
  {
    path: "/edit-report",
    name: "edit-report",
    component: () => import("@/pages/CreateReport")
  },
  {
    path: "/my-reports",
    name: "my-reports",
    component: () => import("@/pages/MyReport")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("@/pages/Login")
  },
  {
    name: "manage-user",
    path: "/employee/all",
    component: () => import("@/pages/EmployeeList")
  },
  {
    path: "/report-histories",
    component: () => import("@/pages/ReportHistory")
  },
  {
    path: "/wait-approve",
    component: () => import("@/pages/WaitApproveReport")
  },
  {
    path: "/create-employee",
    component: () => import("@/pages/CreateEmployee")
  },
  {
    path: "/forget-password",
    component: () => import("@/pages/ForgetPassword")
  },
  {
    path: "/reset-password",
    component: () => import("@/pages/ChangePassword")
  },
  {
    path: "/line-connect",
    component: () => import("@/pages/LineConnect")
  },
  {
    path: "/settings",
    component: () => import("@/pages/Settings")
  },
  {
    path:"*",
    component: () => import('@/pages/PageNotFound')
  }
];
