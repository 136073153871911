const MASTER_DATA = {
    IFE_CATEGORY: "IFE_CATEGORY",
    TYPE_EVENT: "TYPE_EVENT",
    CONDITION_CLOSE: "CONDITION_CLOSE",
    DANGER_TOOL: "DANGER_TOOL",
    IFE_FIX_PROBLEM: "IFE_FIX_PROBLEM"
}

module.exports = {
    MASTER_DATA
}