<template>
  <router-view></router-view>
</template>

<script>
import Vue from "vue";

import "@/assets/css/bootstrap.min.css";
import "@/assets/css/icons.css";
import "@/assets/css/app.css";
import "@/assets/css/custom.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faUserSecret);
Vue.component("font-awesome-icon", FontAwesomeIcon);

export default {
  name: "App"
};
</script>

<style src="../node_modules/handsontable/dist/handsontable.full.css"></style>
