import { GetIFECategories, GetTypeEvents, GetConditionClose, GetDangerTools, GetIFEFixProblem } from "@/api/service"
import { getMasterDataSessionStorage, setMasterDataSessionStorage } from "@/util/util"
import { MASTER_DATA } from "../constants/constant"

const state = {
    listIFECategory: [],
    listTypeEvent: [],
    listConditionClose: [],
    listDangerTool: [],
    listIFEFixProblem: []
}

const getters = {
    getListIFECategory: (state) => {
        return state.listIFECategory
    },
    getListTypeEvent: (state) => {
        return state.listTypeEvent
    },
    getListConditionClose: (state) => {
        return state.listConditionClose
    },
    getListDangerTool: (state) => {
        return state.listDangerTool
    },
    getListIFEFixProblem: (state) => {
        return state.listIFEFixProblem
    }
}

const actions = {
    async getAllIFECategory({ commit }) {
        if (getMasterDataSessionStorage(MASTER_DATA.IFE_CATEGORY) !== null) {
            commit("GET_LIST_IFE_CATEGORY", getMasterDataSessionStorage(MASTER_DATA.IFE_CATEGORY));
        } else {
            try {
                await GetIFECategories().then(res => {
                    let cvList = []
                    res.result.ifeCategory.forEach(i => {
                        cvList.push({
                            value: i.id,
                            key: i.id.toString(),
                            option: i.name
                        })
                    })
                    setMasterDataSessionStorage(MASTER_DATA.IFE_CATEGORY, JSON.stringify(cvList))
                    commit("GET_LIST_IFE_CATEGORY", cvList);
                })
            } catch (err) {
                console.error(err)
            }
        }
    },
    async getAllTypeEvent({ commit }) {
        if (getMasterDataSessionStorage(MASTER_DATA.TYPE_EVENT) !== null) {
            commit("GET_LIST_TYPE_EVENT", getMasterDataSessionStorage(MASTER_DATA.TYPE_EVENT));
        } else {
            try {
                await GetTypeEvents().then(res => {
                    let cvList = []
                    res.result.typeEvent.forEach(i => {
                        cvList.push({
                            value: i.id,
                            key: i.id.toString(),
                            option: i.name
                        })
                    })
                    setMasterDataSessionStorage(MASTER_DATA.TYPE_EVENT, JSON.stringify(cvList))
                    commit("GET_LIST_TYPE_EVENT", cvList);
                })
            } catch (err) {
                console.error(err)
            }
        }
    },
    async getAllConditionClose({ commit }) {
        if (getMasterDataSessionStorage(MASTER_DATA.CONDITION_CLOSE) !== null) {
            commit("GET_LIST_CONDITION_CLOSE", getMasterDataSessionStorage(MASTER_DATA.CONDITION_CLOSE));
        } else {
            try {
                await GetConditionClose().then(res => {
                    let cvListConditionClose = []
                    res.result.conditionClose.forEach(i => {
                        cvListConditionClose.push({
                            value: i.id,
                            key: i.id.toString(),
                            option: i.name
                        })
                    })
                    setMasterDataSessionStorage(MASTER_DATA.CONDITION_CLOSE, JSON.stringify(cvListConditionClose))
                    commit("GET_LIST_CONDITION_CLOSE", cvListConditionClose);
                })
            } catch (err) {
                console.error(err)
            }
        }
    },
    async getAllDangerTool({ commit }) {
        if (getMasterDataSessionStorage(MASTER_DATA.DANGER_TOOL) !== null) {
            commit("GET_LIST_DANGER_TOOL", getMasterDataSessionStorage(MASTER_DATA.DANGER_TOOL));
        } else {
            try {
                await GetDangerTools().then(res => {
                    let cvList = []
                    res.result.dangerTools.forEach(i => {
                        cvList.push({
                            value: i.id,
                            key: i.id.toString(),
                            option: i.name
                        })
                    })
                    setMasterDataSessionStorage(MASTER_DATA.DANGER_TOOL, JSON.stringify(cvList))
                    commit("GET_LIST_DANGER_TOOL", cvList);
                })
            } catch (err) {
                console.error(err)
            }
        }
    },
    async getAllIFEFixProblem({ commit }) {
        if (getMasterDataSessionStorage(MASTER_DATA.IFE_FIX_PROBLEM) !== null) {
            commit("GET_LIST_IFE_FIX_PROBLEM", getMasterDataSessionStorage(MASTER_DATA.IFE_FIX_PROBLEM));
        } else {
            try {
                await GetIFEFixProblem().then(res => {
                    let cvList = []
                    res.result.ifeFixProblem.forEach(i => {
                        cvList.push({
                            value: i.id,
                            key: i.id.toString(),
                            option: i.name
                        })
                    })
                    setMasterDataSessionStorage(MASTER_DATA.IFE_FIX_PROBLEM, JSON.stringify(cvList))
                    commit("GET_LIST_IFE_FIX_PROBLEM", cvList);
                })
            } catch (err) {
                console.error(err)
            }
        }
    }
}

const mutations = {
    GET_LIST_IFE_CATEGORY(state, listData) {
        state.listIFECategory = listData;
    },
    GET_LIST_TYPE_EVENT(state, listData) {
        state.listTypeEvent = listData;
    },
    GET_LIST_CONDITION_CLOSE(state, listData) {
        state.listConditionClose = listData;
    },
    GET_LIST_DANGER_TOOL(state, listData) {
        state.listDangerTool = listData;
    },
    GET_LIST_IFE_FIX_PROBLEM(state, listData) {
        state.listIFEFixProblem = listData;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};