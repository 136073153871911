import request from "./request";

const api = {
  Login: "/users/login",
  ResetPassword: "/users/reset-password",
  ForgetPassword: "/users/forgot-password",
  ReporterGetReportLists: "/users",
  GetReport: "/reports",
  CreateReport: "/reports",
  EditReport: "/reports",
  SupAndAdminGetReportLists: "/reports",
  GetUsers: "/users",
  EditUser: "/users",
  CreateUser: "/users/register",
  // Department
  GetDepartments: "/master-data/departments",
  GetDepartmentsPage: "/master-data/departments/page",
  EditDepartment: "/master-data/departments",
  CreateDepartments: "/master-data/departments/create",
  // IFE Category
  GetIFECategories: "/master-data/ife-category",
  GetIFECategoriesPage: "/master-data/ife-category/page",
  CreateIFECategorys: "/master-data/ife-category/create",
  EditIFECategory: "/master-data/ife-category",
  // Type Event
  GetTypeEvents: "/master-data/type-event",
  GetTypeEventsPage: "/master-data/type-event/page",
  CreateTypeEvents: "/master-data/type-event/create",
  EditTypeEvent: "/master-data/type-event",
  // Condition Close
  GetConditionClose: "/master-data/condition-close",
  GetConditionClosePage: "/master-data/condition-close/page",
  CreateConditionCloses: "/master-data/condition-close/create",
  EditConditionClose: "/master-data/condition-close",
  // Fix Problem
  GetIFEFixProblem: "/master-data/ife-fix-problem",
  GetIFEFixProblemPage: "/master-data/ife-fix-problem/page",
  CreateIFEFixProblems: "/master-data/ife-fix-problem/create",
  EditIFEFixProblem: "/master-data/ife-fix-problem",
  // Danger Tool
  GetDangerTools: "/master-data/danger-tool",
  GetDangerToolPage: "/master-data/danger-tool/page",
  CreateDangerTools: "/master-data/danger-tool/create",
  EditDangerTool: "/master-data/danger-tool",
  GetRoles: "/master-data/roles",
  GetReportStatusId: '/master-data/report-status',
  ExportReport: "/reports/excel",
  ExportAllReport: "/reports/all-list-excel",
  CreateImage: "/image",
  SendEmailExcelReport: "/reports/email",
  ReporterNotify: '/reports/rep/line-notify',
  SupervisorNotify: '/reports/sup/line-notify',
  LineConnect: '/users/add/line-connect',
  CreateUserGroup: '/users/register/group',
  // Image
  getImage: '/image/'
};

export function LineConnect(data) {
  return request({
    url: api.LineConnect,
    method: "put",
    data : {
      username: data.username,
      lineUserID: data.lineUserID
    }
  });
}
export function ReporterNotify(data) {
  return request({
    url: api.ReporterNotify,
    method: "post",
    data : {
      reportID : data.reportID,
      startDate: data.startDate,
      departmentID: data.departmentID,
      reportStatusID: data.reportStatusID
    }
  });
}

export function SupervisorNotify(data) {
  return request({
    url: api.SupervisorNotify,
    method: "post",
    data : {
      reportID : data.reportID,
      startDate: data.startDate,
      reportStatusID: data.reportStatusID
    }
  });
}

export async function SendEmailExcelReport(reportID) {
  return request({
    url: api.SendEmailExcelReport,
    method: "post",
    data: {
      reportID: reportID
    }
  });
}
export function CreateImage(data) {
  const photoFormData = new FormData();
  let newFileName = "img-" + Date.now() + "." + data.file.type.split("/")[1] // generate new file name for img fixed case original file name is th lang
  photoFormData.append("file", data.file, newFileName);
  return request({
    url: api.CreateImage,
    method: "post",
    data: photoFormData
  });
}

export async function ExportReport(reportID) {
  console.log("reportID",reportID);
  return request({
    url: api.ExportReport,
    method: "get",
    responseType: "blob",
    params: {
      reportID
    }
  });
}

export async function ExportAllReport(query, departmentID) {
  // console.log("reportID",reportList);
  return request({
    url: api.ExportAllReport,
    method: "get",
    responseType: "blob",
    params: {
      departmentID: departmentID,
      q: query.q,
      reportStatusID: query.reportStatusID,
      date: query.date,
      shift: query.shift
    }
  });
}

export function Login(userInfo) {
  return request({
    url: api.Login,
    method: "post",
    data: {
      username: userInfo.username,
      password: userInfo.password
    }
  });
}
export function ResetPassword(token, password) {
  return request({
    url: api.ResetPassword,
    method: "post",
    data: {
      token: token,
      newPassword: password
    }
  });
}
export function ForgetPassword(email) {
  return request({
    url: api.ForgetPassword,
    method: "post",
    data: {
      email: email
    }
  });
}

export function ReporterGetReportLists(query, userID) {
  // console.log('reporter userID', userID)
  return request({
    url: api.ReporterGetReportLists + `/${userID}` + "/reports",
    method: "get",
    params: {
      q: query.q,
      reportStatusID: query.reportStatusID,
      limit: query.limit,
      offset: query.offset,
      date: query.date
    }
  });
}

export function SupAndAdminGetReportLists(query, departmentID) {
  // console.log('sup and admin departmentID', departmentID)
  return request({
    url: api.SupAndAdminGetReportLists,
    method: "get",
    params: {
      departmentID: departmentID,
      q: query.q,
      reportStatusID: query.reportStatusID,
      limit: query.limit,
      offset: query.offset,
      date: query.date,
      shift: query.shift
    }
  });
}

export function GetReport(reportID) {
  // console.log('reach here', api.GetReport + `/${reportID}`)
  return request({
    url: api.GetReport + `/${reportID}`,
    method: "get"
  });
}

export function CreateReport(reportData) {
  return request({
    url: api.CreateReport,
    method: "post",
    data: reportData
  });
}


export async function EditReport(reportData, reportID) {
  // !!! must have report Status ID
  return request({
    url: api.EditReport + `/${reportID}`,
    method: "put",
    data: reportData
  });
}

export function GetUsers(query) {
  return request({
    url: api.GetUsers,
    method: "get",
    params: {
      departmentID: query.departmentID,
      q: query.q,
      limit: query.limit,
      offset: query.offset
    }
  });
}

export function EditUser(editedData, userID) {
  return request({
    url: api.EditUser + `/${userID}`,
    method: "put",
    data: editedData
  });
}
export function CreateUser(userData) {
  return request({
    url: api.CreateUser,
    method: "post",
    data: userData
  });
}

export function CreateUserGroup(userData) {
  return request({
    url: api.CreateUserGroup,
    method: "post",
    data: userData
  });
}

export function GetDepartments() {
  return request({
    url: api.GetDepartments,
    method: "get"
  });
}
export function GetDepartmentsPage(query) {
  return request({
    url: api.GetDepartmentsPage,
    method: "get",
    params: {
      limit: query.limit,
      offset: query.offset
    }
  });
}
export function EditDepartment(editedData, departmentID) {
  return request({
    url: api.EditDepartment + `/${departmentID}/edit`,
    method: "put",
    data: editedData
  });
}
export function CreateDepartments(payload) {
  return request({
    url: api.CreateDepartments,
    method: "post",
    data: payload
  });
}

export function GetIFECategories() {
  return request({
    url: api.GetIFECategories,
    method: "get"
  });
}
export function GetIFECategoriesPage(query) {
  return request({
    url: api.GetIFECategoriesPage,
    method: "get",
    params: {
      limit: query.limit,
      offset: query.offset
    }
  });
}
export function CreateIFECategorys(payload) {
  return request({
    url: api.CreateIFECategorys,
    method: "post",
    data: payload
  });
}
export function EditIFECategory(editedData, ifeCategoryID) {
  return request({
    url: api.EditIFECategory + `/${ifeCategoryID}/edit`,
    method: "put",
    data: editedData
  });
}

export function GetTypeEvents() {
  return request({
    url: api.GetTypeEvents,
    method: "get"
  });
}
export function GetTypeEventsPage(query) {
  return request({
    url: api.GetTypeEventsPage,
    method: "get",
    params: {
      limit: query.limit,
      offset: query.offset
    }
  });
}
export function CreateTypeEvents(payload) {
  return request({
    url: api.CreateTypeEvents,
    method: "post",
    data: payload
  });
}
export function EditTypeEvent(editedData, typeEventID) {
  return request({
    url: api.EditTypeEvent + `/${typeEventID}/edit`,
    method: "put",
    data: editedData
  });
}

export function GetConditionClose() {
  return request({
    url: api.GetConditionClose,
    method: "get"
  });
}
export function GetConditionClosePage(query) {
  return request({
    url: api.GetConditionClosePage,
    method: "get",
    params: {
      limit: query.limit,
      offset: query.offset
    }
  });
}
export function EditConditionClose(editedData, conditionCloseID) {
  return request({
    url: api.EditConditionClose + `/${conditionCloseID}/edit`,
    method: "put",
    data: editedData
  });
}
export function CreateConditionCloses(payload) {
  return request({
    url: api.CreateConditionCloses,
    method: "post",
    data: payload
  });
}

export function GetIFEFixProblem() {
  return request({
    url: api.GetIFEFixProblem,
    method: "get"
  });
}
export function GetIFEFixProblemPage(query) {
  return request({
    url: api.GetIFEFixProblemPage,
    method: "get",
    params: {
      limit: query.limit,
      offset: query.offset
    }
  });
}
export function CreateIFEFixProblems(payload) {
  return request({
    url: api.CreateIFEFixProblems,
    method: "post",
    data: payload
  });
}
export function EditIFEFixProblem(editedData, ifeFixProblemID) {
  return request({
    url: api.EditIFEFixProblem + `/${ifeFixProblemID}/edit`,
    method: "put",
    data: editedData
  });
}

export function GetDangerTools() {
  return request({
    url: api.GetDangerTools,
    method: "get"
  });
}
export function GetDangerToolPage(query) {
  return request({
    url: api.GetDangerToolPage,
    method: "get",
    params: {
      limit: query.limit,
      offset: query.offset
    }
  });
}
export function CreateDangerTools(payload) {
  return request({
    url: api.CreateDangerTools,
    method: "post",
    data: payload
  });
}
export function EditDangerTool(editedData, dangerToolID) {
  return request({
    url: api.EditDangerTool + `/${dangerToolID}/edit`,
    method: "put",
    data: editedData
  });
}

export function GetRoles() {
  return request({
    url: api.GetRoles,
    method: "get"
  });
}

export function GetReportStatusId() {
  return request({
    url: api.GetReportStatusId,
    method: "get"
  })
}

export function GetImage(imageName) {
  return request({
    url: api.getImage + imageName,
    method: "get",
    responseType: "blob"
  })
}