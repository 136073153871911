function getSystemName(url) {
    const IFE_SYSTEM = "ife.systemstone.com"
    const ESS_SYSTEM = "ess.systemstone.com"
    try {
        if (url.includes(IFE_SYSTEM)) {
            localStorage.setItem("System-Name", "IFE")
            return {
                main: "Manage IFE System",
                sub: "สร้างรายงาน IFE ตรวจสอบและอนุมัติ",
                footer: "IFE"
            }
        } else if (url.includes(ESS_SYSTEM)) {
            localStorage.setItem("System-Name", "ESS")
            return {
                main: "Environmental Safety System",
                sub: "ระบบตรวจสอบสภาพการณ์",
                footer: "ESS"
            }
        } else {
            return {
                main: "Environmental Safety System",
                sub: "ระบบตรวจสอบสภาพการณ์",
                footer: "ESS"
            }
        }
    } catch (e) {
        console.error(e)
    }
}

function setMasterDataSessionStorage(name, data) {
    sessionStorage.setItem(name, data);
}

function getMasterDataSessionStorage(name) {
    return JSON.parse(sessionStorage.getItem(name));
}

function getProbabilityName(id) {
    switch (id) {
        case 1:
            return "1 ต่ำ (ความถี่ในการเกิดอุบัติเหตุ 1 ครั้งต่อปีหรือมากกว่า)"
        case 2:
            return "2 ปานกลาง (ความถี่ในการเกิดอุบัติเหตุ 1 ครั้ง ต่อเดือน)"
        case 3:
            return "3 สูง (ความถี่ในการเกิดอุบัติเหตุ 1 ครั้ง ต่อสัปดาห์)"
    }
}

function getSeverityName(id) {
    switch (id) {
        case 1:
            return "1 ต่ำ (บาดเจ็บเล็กน้อย ระเคายเคือง เหตุรำคาญ)"
        case 2:
            return "2 ปานกลาง (บาดแผลฉีกขาด แผลไฟไหม้ กระดูกร้าว)"
        case 3:
            return "3 สูง (เจ็บป่วยถึงขั้นเสียชีวิต)"
    }
}

function getReportStatusName(id) {
    switch (id) {
        case 1:
            return "เสร็จสมบูรณ์"
        case 2:
            return "อยู่ระหว่างดำเนินการ"
        case 3:
            return "ยังไม่ได้ดำเนินการ"
    }
}

export { getSystemName, getMasterDataSessionStorage, setMasterDataSessionStorage, getProbabilityName, getSeverityName, getReportStatusName };