import Vue from "vue";
import Vuex from "vuex";

import home from "./home.module";
import auth from "./auth.module";
import toggleMenu from "./toggleMenu";
import master from "./master.module";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  modules: {
    home,
    auth,
    toggleMenu,
    master
  }
});
