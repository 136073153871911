import axios from "axios";
import storage from "store";
import notification from "ant-design-vue/es/notification";
import { VueAxios } from "./axios";

const URL_API = process.env.VUE_APP_API_BASE_URL;
const request = axios.create({
  baseURL: URL_API
});

const axiosConfig = {
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*"
  }
};

const alertError = (message, description) => {
  notification.error({
    message,
    description
  });
};

const getRefreshToken = () => {
  // console.log('asdf', storage.get('Refresh-Token'))
  return storage.get("Refresh-Token");
};

const setTokens = ({ accessToken, refreshToken }) => {
  storage.set("Access-Token", accessToken, 7 * 24 * 60 * 60 * 1000);
  storage.set("Refresh-Token", refreshToken, 7 * 24 * 60 * 60 * 1000);
};

const requestNewAccessToken = async refreshToken => {
  try {
    const refreshTokenResponse = await axios.post(
      URL_API + "/users/refresh-token",
      { refreshToken: refreshToken },
      axiosConfig
    );

    setTokens(refreshTokenResponse.data.result);
    // eslint-disable-next-line standard/computed-property-even-spacing
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${refreshTokenResponse.data.result.accessToken}`;

    return refreshTokenResponse.data.result.accessToken;
  } catch (error) {
    return error;
  }
};

const retryOriginalRequest = async error => {
  try {
    const accessToken = await requestNewAccessToken(getRefreshToken());
    error.config.headers["Authorization"] = `Bearer ${accessToken}`;
    // console.log('accessTokenn', accessToken)
    // console.log('error config header', error.config)
    return (await axios(error.config)).data;
  } catch (err) {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    return Promise.reject(err);
  }
};

const errorHandler = async error => {
  console.log("error", error);
  switch (error.response.status) {
    case 401:
      if (getRefreshToken()) {
        return retryOriginalRequest(error);
      } else {
        alertError("Unauthorized", error.response.data.message);
        return Promise.reject(error);
      }
    case 403:
      alertError("Forbidden", error.response.data.message);
      return Promise.reject(error);
    case 404:
      alertError("Error, กรุณา Login ใหม่", error.response.data.message);
      return Promise.reject(error);
    default:
      return Promise.reject(error);
  }
};

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get("Access-Token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
  }
  return config;
}, errorHandler);

// request.interceptors.request.use(config => {
//   const token = storage.get('Access-Token')
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`
//     config.headers['Content-Type'] = 'application/json'
//     config.headers['Accept'] = 'application/json'
//   }
//   return config
// })

request.interceptors.response.use(response => {
  return response.data;
}, errorHandler);

// request.interceptors.response.use(response => {
//   return response.data
// })
const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request);
  }
};

export default request;
export { installer as VueAxios, request as axios };
