import Vue from "vue";
import App from "./App.vue";
import { routes } from "./routes";
import { makeServer } from "./server";
import store from "./store";
import VueRouter from "vue-router";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

Vue.use(VueRouter);
Vue.use(Antd);
Vue.config.productionTip = false;

const router = new VueRouter({
  mode: "history",
  routes
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    let systemName = localStorage.getItem("System-Name")
    if ('IFE' === systemName) {
      document.title = 'IFE System';
    } else {
      document.title = 'ESS System';
    }
  });
});

if (process.env.NODE_ENV === "developmsdsdfent") {
  makeServer({ environment: "development" });
}

// Ensure we checked auth before each page load.

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
